<template>
	<v-md-editor :disabled="true" @upload-image="onImageUpload" @save="save" :disabled-menus="[]" :autofocus="true"
							 v-model="model" :right-toolbar="rightToolbarList"
							 :toolbar="extraToolbars" :left-area-visible="true"></v-md-editor>
</template>
<script>
import api from '@/api'

export default {
	name: 'md-editor',
	props: {
		value: {},
		extraToolbars: {}
	},
	computed: {
		model: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit("input", val)
			}
		},
		rightToolbarList() {
			let ret = "preview toc sync-scroll fullscreen"

			if (this.extraToolbars) {
				let keys = Object.keys(this.extraToolbars)

				if (keys.length > 0) {
					ret += ` | ${keys.join(' | ')}`
				}
			}
			return ret
		}
	},
	methods: {
		async onImageUpload(event, insertImage, files) {
			// Get the files and upload them to the file server, then insert the corresponding content into the editor
			const image_url = await api.Localizations.LocalizedGuides.uploadImage(files[0])

			// Here is just an example
			insertImage({
				url: image_url,
				desc: 'Description',
			});
		},
		async save(text, html) {
			this.$emit("save", text)
		}
	}
}
</script>
