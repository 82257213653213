var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.cacheKey,staticClass:"grid-container overflow-hidden",staticStyle:{"width":"100vw","height":"100vh"}},[_c('div',{staticClass:"navigation"},[_c('nav',{staticClass:"p-3",attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'projects-index'}}},[_vm._v("Projects")])],1),(_vm.project)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
							name: 'projects-editor-uuid-index',
							params: {
								uuid: _vm.project.Uuid
							}
						}},domProps:{"textContent":_vm._s(`${_vm.project.Code} - ${_vm.project.Name}`)}})],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item"},[(_vm.configuration)?_c('router-link',{attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-localizations-index',
					params: {
						uuid: this.$route.params.uuid,
						cuuid: this.$route.params.cuuid
					}
				}}},[_vm._v(_vm._s(_vm.configuration.Name)+" "+_vm._s(`v. ${_vm.configuration.Version}`)+" ")]):_vm._e()],1),(_vm.localization_project)?_c('li',{staticClass:"breadcrumb-item active"},[_c('router-link',{attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
					params: {
						uuid: this.$route.params.uuid,
						cuuid: this.$route.params.cuuid,
						project_id: this.$route.params.project_id
					}
				}}},[_vm._v(_vm._s(_vm.localization_project.Name)+" ")])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item active"},[_c('router-link',{attrs:{"to":{
					name: 'projects-editor-uuid-cuuid-localizations-project_id-guide-index',
					params: {
						uuid: this.$route.params.uuid,
						cuuid: this.$route.params.cuuid,
						project_id: this.$route.params.project_id
					}
				}}},[_vm._v("Game guide ")])],1),(_vm.language)?_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" "+_vm._s(_vm.language.Name)+" ")]):_vm._e()])])]),(_vm.guide)?_c('md-editor',{staticClass:"editor",attrs:{"extra-toolbars":_vm.extraToolbars,"value":_vm.guide.Body},on:{"save":_vm.save}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }